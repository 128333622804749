import React from 'react';
import Slider from 'react-slick';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
//components
import Layout from '../../components/mini/layout';
import SEO from '../../components/mini/seo';
import LocationCard from '../../components/locationcard';
import settings from '../../../settings';
import BmwPcLocations from '../../components/mini/bmwPcLocations';

const zentrum = `${settings.IMAGES_BASE_URL}/v2/images/locations/desktop/bmw-zentrum-south-carolina.jpg`;
const golfcourse = `${settings.IMAGES_BASE_URL}/v2/images/locations/desktop/palm-springs-golf-course.jpg`;
const herobackground = `${settings.IMAGES_BASE_URL}/v2/images/makeitaminivacation.jpg`;
const joshuatree = `${settings.IMAGES_BASE_URL}/v2/images/mini/thermal-performance-center-building.jpg`;
const spartanburgMobile = `${settings.IMAGES_BASE_URL}/v2/images/locations/desktop/south-carolina-bmw-performance-center.jpg`;
const spartanburgTrackMobile = `${settings.IMAGES_BASE_URL}/v2/images/spartanburg-overhead-track-banner.jpg`;
const thermalTrackMobile = `${settings.IMAGES_BASE_URL}/v2/images/thermal-overhead-track-banner.jpg`;
const arrowLeft = `${settings.IMAGES_BASE_URL}/v2/images/arrowleft-white.svg`;
const arrowRight = `${settings.IMAGES_BASE_URL}/v2/images/arrowright-white.svg`;
const greenvile = `${settings.IMAGES_BASE_URL}/v2/images/mini/greenville-bridge.jpg`
const PrevArrow = (props) => {
  const { className, onClick } = props
  return (
      <button
          className={className}
          onClick={onClick}
      >
         <LazyLoadImage effect="blur" src={arrowRight} alt="Slider Previous Arrow" />
      </button>
  );
}
const NextArrow = (props) => {
  const { className, onClick } = props
  return (
      <button
          className={className}
          onClick={onClick}
      >
         <LazyLoadImage effect="blur" src={arrowLeft} alt="Slider Next Arrow" />
      </button>
  );
}

class DriverSchoolPage extends React.Component {
  state = {
    width: ''
  };

  componentDidMount() {
    this.setState({
      width: window.innerWidth
    });
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width < 1200;
    const isDesktop = width >= 1200;



    const settings = {
      dots: false,
      arrows: true,
      speed: 100,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <PrevArrow /> ,
      prevArrow: <NextArrow />
    };

    return (
      <Layout>
      <SEO title="THE MINI DRIVING EXPERIENCE | Locations" />

      <LazyLoadImage effect="blur"  src={herobackground}  alt="MINI Locations" />
      <section className="mini__location">
        <div className="container">
          <div className="location__header">
            <h2 className="linethrough-header">
            LOCATIONS
            </h2>
            <p>
            Whether choosing our location on the East Coast or West Coast, you’ll find<br/> beautiful surroundings, an attentive staff and,
            of course, plenty of MINI excitement.
            </p>

            <a href="https://www.visitgreaterpalmsprings.com/" target="_blank" rel="noopener noreferrer"><button className="btn btn-black">LEARN MORE</button></a>

          </div>
        </div>
        {isMobile && (
              <React.Fragment>
                <div className="location__type__custom">
                  <div className="location__mobile" id="southcarolina">
                    <LazyLoadImage effect="blur" src={spartanburgMobile} alt="SPARTANBURG, SOUTH CAROLINA" />
                    <div className="container">
                      <div className="location__mobile__content">
                        <h3 className="white__txt">SPARTANBURG, SOUTH CAROLINA</h3>
                        <p className="white__txt">
                        Make a vacation out of your visit to the MINI Driving Experience in Greenville-Spartanburg. With numerous hotels,
                        nearby golf courses and charming downtown Greenville a short drive away, there is plenty to explore when not behind
                        the wheel of a MINI.
                        </p>
                        <div className="buttons">
                          <a href="https://www.visitgreenvillesc.com/" target="_blank" rel="noopener noreferrer">
                            <button className="btn btn-bordered">SEE GREENVILLE</button>
                          </a>
                          <a href="https://www.visitspartanburg.com/" target="_blank" rel="noopener noreferrer">
                            <button className="btn btn-bordered">SEE SPARTANBURG</button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="location__mobile">
                    <LazyLoadImage effect="blur" src={spartanburgTrackMobile} alt="The Track" />
                    <div className="container">
                      <h3>THE TRACK</h3>
                      <p>
                      Fast turns, a back straight perfect for speed exercises and a polished wet concrete skid pad make our Spartanburg track
                      the perfect place to hone your driving skills
                      </p>
                    </div>
                  </div>
                  <Slider {...settings}>
                    <div className="location__card__mobile">
                      <LazyLoadImage effect="blur" src={zentrum} alt="BMW Zentrum South Carolina" />
                      <div className="container">
                        <h4>THE ZENTRUM and factory tour</h4>
                        <p>
                          Explore BMW’s past while marveling at its future with a tour of the Zentrum Museum. Featuring
                          many of BMW’s most famous models, you’ll get up close with our history and what makes a BMW so
                          special. Open daily from 9 AM to 5:30 PM.
                        </p>
                        <a
                          href="https://www.bmwgroup-werke.com/spartanburg/en/plant-tours-zentrum-museum.html"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="btn btn-bordered">ZENTRUM</button>
                        </a>&emsp;&emsp;
                        <a
                          href="https://www.bmwgroup-werke.com/spartanburg/en/plant-tours-zentrum-museum.html"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="btn btn-bordered">FACTORY TOUR</button>
                        </a>
                      </div>
                    </div>
                    <div className="location__card__mobile">
                      <LazyLoadImage effect="blur" src={greenvile} alt="BMW Factory Tour" />
                      <div className="container">
                        <h4>Greenville</h4>
                        <p>
                          Located just across the street from the Performance Center is our manufacturing plant for the X3, X4, X5, X6 and X7. Schedule a tour and watch in amazement as over 1,500 vehicles are produced each day. Please visit the plant website for more information.
                        </p>
                        <a
                          href="https://www.visitgreenvillesc.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="btn btn-bordered">FIND OUT MORE</button>
                        </a>
                      </div>
                    </div>
                  </Slider>
                </div>
                <div className="location__type">
                  <div className="location__mobile">
                    <LazyLoadImage effect="blur" src={thermalTrackMobile} alt="Thermal, California" />
                      <div className="container">
                        <div className="location__mobile__content">
                          <h3>The Track at Thermal Club.</h3>
                          <p>
                          Set against the backdrop of a beautiful mountain range, The Thermal Club is an exclusive track that offers three unique layouts,
                          plus our own challenge course to encourage maximum thrills.
                          </p>
                          <a href="https://www.visitgreaterpalmsprings.com/" rel="noopener noreferrer" target="_blank">
                            <button className="btn btn-bordered">EXPLORE PALM SPRINGS</button>
                          </a>
                        </div>
                      </div>
                    </div>
                  <Slider {...settings}>
                    <div className="location__card__mobile">
                      <LazyLoadImage effect="blur" src={joshuatree} alt="Joshua Tree National Park" />
                      <div className="container">
                        <h4>Joshua tree</h4>
                        <p>
                          Experience amazing natural beauty at Joshua Tree National Park located just outside of Palm
                          Springs. Camp, hike, climb and take in a night sky like no other.
                        </p>
                        <a href="https://www.nps.gov/jotr/index.htm" target="_blank" rel="noopener noreferrer">
                          <button className="btn btn-black">LEARN MORE</button>
                        </a>
                      </div>
                    </div>
                    <div className="location__card__mobile">
                      <LazyLoadImage effect="blur" src={golfcourse} alt="Palm Springs Golf Course" />
                      <div className="container">
                        <h4>GOLF</h4>
                        <p>
                          Home to some of the most beautiful courses in the world, Palm Springs has plenty to offer the
                          golfing enthusiast. You won’t know whether to swing or stare.
                        </p>
                        <a
                          href="https://www.visitgreaterpalmsprings.com/play/things-to-do/golf/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="btn btn-black">LEARN MORE</button>
                        </a>
                      </div>
                    </div>
                  </Slider>
                </div>
              </React.Fragment>
            )}
            {isDesktop && (
              <React.Fragment>
                <div className="location__type__custom">
                  <div className="location__spartanburg" id="southcarolina">
                    <div className="container">
                      <div className="location__spartanburg__content__custom">
                        <h3>SPARTANBURG, SOUTH CAROLINA</h3>
                        <p>
                        Make a vacation out of your visit to the MINI Driving<br/> Experience in Greenville-Spartanburg. With numerous<br/> hotels,
                        nearby golf courses and charming downtown<br/> Greenville a short drive away, there is plenty to explore<br/> when not behind
                        the wheel of a MINI.
                        </p>
                        <div className="buttons">
                          <a href="https://www.visitgreenvillesc.com/" target="_blank" rel="noopener noreferrer">
                            <button className="btn btn-bordered">SEE GREENVILLE</button>
                          </a>
                          <a href="https://www.visitspartanburg.com/" target="_blank" rel="noopener noreferrer">
                            <button className="btn btn-bordered">SEE SPARTANBURG</button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="location__track location__track__spartanburg">
                    <div className="location__track__text">
                      <h3>THE TRACK</h3>
                      <p>
                      Fast turns, a back straight perfect for speed exercises and a polished wet concrete skid pad make our Spartanburg track
                      the perfect place to hone your driving skills
                      </p>
                    </div>
                  </div>
                  <div className="location__cards">
                    <div className="container">
                      <div className="row">
                        <LocationCard
                          image={zentrum}
                          imageAlt="BMW Zentrum South Carolina"
                          title="THE ZENTRUM and factory tour"
                          text="Explore BMW’s past while marveling at its future with a tour of the Zentrum Museum, featuring many of BMW’s most famous models. You may also schedule a factory tour and watch as over 1,500 BMWs are produced each day."
                          btnText="ZENTRUM"
                          url="https://www.bmwgroup-werke.com/spartanburg/en/plant-tours-zentrum-museum.html"
                          anotherUrl="https://www.bmwgroup-werke.com/spartanburg/en/plant-tours-zentrum-museum.html"
                          anotherBtnText="FACTORY TOUR"
                        />
                        <LocationCard
                          image={greenvile}
                          imageAlt="BMW Factory Tour"
                          title="Greenville"
                          text="Discover true Southern charm with a visit to nearby Greenville. Sample local cuisine and enjoy the nightlife while taking in the beautiful scenery around you."
                          btnText="FIND OUT MORE"
                          url="https://www.visitgreenvillesc.com/"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="location__type__custom">
                <div className="location__custom_track location__track__thermal">
                    <div className="location__track__text">
                      <h3>The Track at Thermal Club</h3>
                      <p>
                        Set against the backdrop of a beautiful mountain range, The Thermal Club is an exclusive track
                        that offers three unique layouts, plus our own challenge course to encourage maximum thrills.
                      </p><br/>
                      <a href="https://www.visitgreaterpalmsprings.com/" rel="noopener noreferrer" target="_blank">
                          <button className="btn btn-bordered">EXPLORE PALM SPRINGS</button>
                        </a>
                    </div>
                  </div>
                  <div className="location__cards">
                    <div className="container">
                      <div className="row">
                        <LocationCard
                          image={joshuatree}
                          imageAlt="Joshua Tree National Park"
                          title="Joshua tree"
                          text="Experience amazing natural beauty at Joshua Tree National Park located just outside of Palm Springs. Camp, hike, climb and take in a night sky like no other."
                          btnText="LEARN MORE"
                          url="https://www.nps.gov/jotr/index.htm"
                          color="btn btn-black"
                        />
                        <LocationCard
                          image={golfcourse}
                          imageAlt="Palm Springs Golf Course"
                          title="GOLF"
                          text="Home to some of the most beautiful courses in the world, Palm Springs has plenty to offer the golfing enthusiast. You won’t know whether to swing or stare."
                          btnText="LEARN MORE"
                          url="https://www.visitgreaterpalmsprings.com/play/things-to-do/golf/"
                          color="btn btn-black"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
            <BmwPcLocations/>
      </section>
    </Layout>
    );

  }

}
export default DriverSchoolPage;
